@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.kg-slider-wrap-area .kg-inner-slider .kg-row {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    align-items: center;
    box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-left-area {
    width: 50%;
    padding: 0 10px;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-right-area {
    width: 50%;
    padding: 0 10px;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-right-area .kg-inner-right figure:after {
    padding-bottom: 100%;
    content: "";
    position: relative;
    display: block;
    width: 100%;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-right-area .kg-inner-right figure {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    vertical-align: middle;
   margin: 0;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-right-area .kg-inner-right figure img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: contain;
    object-position: center;
    width: auto;
    height: auto;
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-left-area .kg-inner-left > h2 {
    margin-top: 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 2em;
    margin-bottom: 24px;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-left-area .kg-inner-left > p {
    font-size: 1.1667rem;
    margin-top: 0;
    margin-bottom: 30px;
    color: #fff;
}
.kg-slider-wrap-area .kg-inner-slider .kg-row .kg-left-area .kg-inner-left a.kg-leran-more-btn {
    padding: 12px 28px;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    display: block;
    width: fit-content;
}