@font-face {
    font-family: 'AktivGrotesk';
    src:url('AktivGrotesk-Regular.woff2') format('woff2'),
        url('AktivGrotesk-Regular.woff') format('woff'),
        url('AktivGrotesk-Regular.ttf') format('truetype'),
        url('AktivGrotesk-Regular.svg#AktivGrotesk-Regular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AktivGrotesk-Medium';
    src:url('AktivGrotesk-Medium.woff2') format('woff2'),
        url('AktivGrotesk-Medium.woff') format('woff'),
        url('AktivGrotesk-Medium.ttf') format('truetype'),
        url('AktivGrotesk-Medium.svg#AktivGrotesk-Medium') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AktivGrotesk-Bold';
    src:url('AktivGrotesk-Bold.woff2') format('woff2'),
        url('AktivGrotesk-Bold.woff') format('woff'),
        url('AktivGrotesk-Bold.ttf') format('truetype'),
        url('AktivGrotesk-Bold.svg#AktivGrotesk-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


.react-multiple-carousel__arrow--left{display: none;}
.react-multi-carousel-dot button{
    border-color: #fff;
    background: #fff;
}
.react-multi-carousel-dot button:hover:active,
.react-multi-carousel-dot--active button{
    border-color: #3377ff;
    background: #3377ff;
}
.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left{
    background: none !important;
}
.react-multiple-carousel__arrow--right::before,
.react-multiple-carousel__arrow--left::before{
    content: '' !important;
}

@media only screen and (min-width: 1024px) {
    .consultantreel {
      margin: 0 -4em;
      padding: 0 4em;
    }
    .react-multi-carousel-list,
    .react-multi-carousel-track {
        padding-bottom: 3em;
    }
}
@media only screen and (max-width: 1024px) {
    .consultantreel > div:nth-child(4n+3),
    .consultantreel > div:nth-child(4n+2) {
        display: none;
    }
    .react-multi-carousel-list,
    .react-multi-carousel-track {
        padding-bottom: 2vw;
    }
    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left{
        top: 22vw;
        position: absolute;
        right: 5%;
    }
}
